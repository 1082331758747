/* common */
.loading {
  font-size: 84px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-align: center;
  span {
    display: inline-block;
    margin: 0 -0.05em;
  }
}

.container-animation-loading {
  color: #3f51b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* code #3 */
.loading03 span {
  margin: 0 -0.075em;
  animation: loading03 0.7s infinite alternate;
}

.loading03 span:nth-child(1) {
  animation-delay: 0s;
}
.loading03 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading03 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading03 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading03 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading03 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading03 span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading03 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
